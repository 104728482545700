<template>
  <div class="addclass">
    <div class="content">
      <div class="cont-mid">
        <el-form ref="form" :rules="rules" :model="form" label-width="190px">
          <el-form-item label="课节名称：" prop="lessonsName">
            <el-input v-model="form.lessonsName" maxlength="100" show-word-limit style="width:555px"></el-input>
          </el-form-item>

          <el-form-item label="开放时间：" required>
            <dateTimePikcer @dateTimePicker="handleTimeChange" :pickerType="'point'"></dateTimePikcer>
          </el-form-item>

          <el-form-item label="主讲教师：" :required="true">
            <el-input v-model="form.lecturerInfo.name" style="width:300px"></el-input>
          </el-form-item>

          <el-form-item label="教师简介：">
            <el-input type="textarea" class="textarea" :rows="6" maxlength="2500" show-word-limit
              v-model="form.lecturerInfo.introduce" resize="none"></el-input>
          </el-form-item>

          <el-form-item label="头像：">
            <UploadCover @uploadCover="handleAvatarChange"></UploadCover>
          </el-form-item>
          <el-form-item label="课节附件：">
            <uploadFile @uploadFile="handleFileChange" :name="'lessonIdentifiers'" :sourceType=2 />
          </el-form-item>

          <el-form-item label="视频：" :required="true">
            <uploadFile @uploadFile="handleFileChange" :name="'videoIdentifiers'" :tips="{ image: false, url: true }"
              :sourceType=1 />
          </el-form-item>

          <el-form-item class="el-form-item-btns">
            <el-button @click="$router.push(`/lesson/${$route.params.id}?breadNumber=4`)">取消</el-button>
            <el-button type="primary" @click="onSubmit">确定</el-button>
          </el-form-item>
          <br />
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "newcourse",
  data() {
    return {
      form: {
        lecturerInfo:{
          name:'',
          introduce:''
        }
      },
      rules: {
        lessonsName: [{
          required: true,
          message: "请输入课节名称",
          trigger: "blur"
        }],
        video: [{
          required: true
        }],
        teacherName: [{
          required: true,
          message: "请输入教师名称",
          trigger: "blur"
        }],
      },
    };
  },
  computed: {},
  components: {},
  methods: {
    handleTimeChange(data) {
      // console.log(data);
      this.form.lessonsBeginDate = data.bDate;
      this.form.lessonsBeginTime = data.bTime;
    },
    handleFileChange(data) {
      // console.log(data);
      this.form[data.name] = data.fileMD5List;
    },
    onSubmit() {
      if (this.form.lessonsName.trim() == '') {
        this.$message.error('课程名称不能为空');
        return;
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.commitForm();
        }
      })
    },
    async commitForm() {
      this.form.courseProjectId = this.$route.params.id;
      console.log('form:',this.form);
      this.form.lecturerInfo =  JSON.stringify(this.form.lecturerInfo);
      const loading = this.$loading(this.$store.state.user.loadingConfig);
      let resData = await this.$Api.Course.creatLesson(this.form);
      if (resData.code === 200) {
        loading.close();
        this.$notify({
          title: "成功",
          message: "创建成功",
          type: "success"
        });
        setTimeout(() => {
          this.$router.push(`/lesson/${this.$route.params.id}?breadNumber=4`);
        }, 500);
      } else {
        loading.close();
        this.$notify.error({
          title: "错误",
          message: resData.msg
        });
      }
    },
    // 处理头像
    handleAvatarChange(data) {
      console.log('头像:',data)
      this.form.lecturerInfo.cover = data.md5
    },
  },
  deactivated() {
    this.form = {
      lecturerInfo:{
          name:'',
          introduce:''
        }
    };
    this.$refs["form"].resetFields();
  },
  created() { }
};
</script>

<style lang="less"></style>